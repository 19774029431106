import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
//import './fonts/stylesheet.css';
import 'swiper/swiper-bundle.css';
import '../dist/tailwind.css';
import './main.scss';

import Swiper from 'swiper/bundle';
//import initPhotoSwipeFromDOM from './js/photoswipe.js';
import CookiesEuBanner from 'cookies-eu-banner';
// configure Swiper to use modules
import Alpine from 'alpinejs'

window.Alpine = Alpine

const noop = () => {};

const requestTimeout = (fn, delay, registerCancel) => {
  const start = new Date().getTime();

  const loop = () => {
    const delta = new Date().getTime() - start;

    if (delta >= delay) {
      fn();
      registerCancel(noop);
      return;
    }

    const raf = requestAnimationFrame(loop);
    registerCancel(() => cancelAnimationFrame(raf));
  };

  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

window.rotateFeature = function(slot, data) {
  let cancel = noop;
  const registerCancel = fn => cancel = fn;

  return {
    slotURL: slot,
    defaultRotationTime: 20 * 1000,
    inView: false,
    data: data,
    init() {
      let checkFeatureInView = ([e]) => {
        this.inView = e.intersectionRatio > 0.3;
      };
      this.observer = new IntersectionObserver(checkFeatureInView, {threshold: 0.3});
      this.observer.observe(this.$el);
      this.play();
    },
    next() {
      fetch(this.slotURL).then(response => response.json()).then(data => {
        this.data = data
        if (this.data !== data) {
          this.data = data;
          fetch(this.data.impress).then();
        }
      });
    },
    play() {
      if (this.inView) {
        this.next();
      }
      requestTimeout(() => this.play(), this.defaultRotationTime, registerCancel);
    }
  }
}

window.MainNavigation = function() {

  return {
    open: false,
    isSticky: false,
    init() {
      let checkSticky = ([e]) => {
        this.isSticky = e.intersectionRatio < 1;
      };
      this.observer = new IntersectionObserver(checkSticky, {threshold: [1]});
      this.observer.observe(this.$el);
    },
  };
}

window.addEventListener('load', () => {
  Alpine.start();

  var HomeCarousel = new Swiper('.home-carousel', {
    spaceBetween: 0,
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    //effect: 'fade',
    speed: 900,
    // fadeEffect: {
    //   crossFade: true
    // },
  });

});
